import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { BEST_REVIEWS_URL } from '../../constants/endpoints';
import Review from './review';
import SwiperComponent from '../ui-kit/swiper';

import { Title } from '../../styledComponents';
import { ReviewsWrapper } from './styles';

const ReviewBlock = ({ titleText, content, withoutMarginBottom, withoutMarginTop, LTR }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const getReviews = async () => {
      const { data } = await axios.get(BEST_REVIEWS_URL);
      setReviews(data);
    };
    getReviews();
  }, []);

  return (
    <ReviewsWrapper
      id="reviews"
      withoutMarginBottom={withoutMarginBottom}
      withoutMarginTop={withoutMarginTop}
      LTR
    >
      {Boolean(titleText) && <Title>{titleText}</Title>}
      {Boolean(reviews.length) && (
        <SwiperComponent name="reviews-swiper" options={{ spaceBetween: isMobile ? 36 : 30 }}>
          {reviews.map((review) => (
            <Review key={review.uuid} className="swiper-slide" {...review} />
          ))}
        </SwiperComponent>
      )}
    </ReviewsWrapper>
  );
};

export default ReviewBlock;
