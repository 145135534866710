import styled from 'styled-components';
import { breakpoints } from '../../../constants/breakpoints';

export const SwiperWrapper = styled.div`
  overflow: hidden;
  width: 100%;

  .swiper-button-next:not(.custom),
  .swiper-button-prev:not(.custom) {
    border-radius: 50%;
    background-color: #ffffff;
    width: 30px;
    height: 30px;
    box-shadow: 0px 4px 16px rgba(36, 93, 150, 0.15);
  }

  .nav.-prev {
    transform: rotate(180deg);
  }

  @media ${breakpoints.mobile} {
    & {
      width: 100%;
      height: auto;
    }

    .swiper-button-next,
    .swiper-button-disabled,
    .swiper-button-prev,
    .swiper-button-disabled {
      display: none;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
      font-size: 0;
    }
  }

  @media ${breakpoints.tablet} {
    .swiper-button-prev:not(.swiper-button-disabled) ~ .decorator:before {
      content: '';
      position: absolute;
      top: 0;
      min-width: 75px;
      height: 100%;
      background: linear-gradient(268.85deg, #fffdfd 4.06%, rgba(255, 253, 253, 0) 49.84%);
      z-index: 4;
      transform: rotate(180deg);
    }

    .swiper-button-next:not(.swiper-button-disabled) ~ .decorator:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      min-width: 181px;
      height: 100%;
      background: linear-gradient(268.85deg, #fffdfd 4.06%, rgba(255, 253, 253, 0) 49.84%);
      z-index: 4;
    }
  }

  @media ${breakpoints.laptop} {
    .swiper-button-next:not(.swiper-button-disabled),
    .swiper-button-prev:not(.swiper-button-disabled) {
      display: flex;
    }
  }
`;
