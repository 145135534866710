import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';

import { breakpoints } from '../../../../../constants/breakpoints';

export const Header = styled(AppBar)`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      align-items: center;
      background: #fff;
      width: 100%;
      box-shadow: none;
    }

    .back-btn {
      padding-right: 16px;
    }

    .title {
      font-size: 19px;
      line-height: 20px;
      color: #222222;
    }
  }

  @media ${breakpoints.tablet} {
    .contacts {
      display: none;
    }
  }

  @media ${breakpoints.laptop} {
    .contacts {
      display: block;
      margin: 0 0 0 auto;
      color: rgb(6, 107, 214);
      font-size: 14px;
      cursor: pointer;
      padding-right: 20px;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const Content = styled.div`
  @media ${breakpoints.mobile} {
    && {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;
      width: 100%;
      max-width: 1440px;
      box-sizing: border-box;
      background: #fff;
      padding: 0 3%;
    }

    .logo {
      width: 40px;
      height: 24px;
      margin: 0;
    }

    .toolbar {
      display: flex;
      justify-content: flex-end;
      width: 37px;
      min-height: 100%;
      padding: 0;
    }

    .icon-wrap {
      width: 35px;
      height: 35px;
      z-index: 1201;
      color: #000;
      padding: 0;
    }

    .avatar {
      width: 35px;
      height: 35px;
    }

    .menu-icon {
      width: 35px;
      height: 35px;
      color: #5d5d5d;
    }

    .link {
      display: flex;
    }
  }

  @media ${breakpoints.tablet} {
    .toolbar {
      width: auto;
      margin-left: auto;
    }

    .logo {
      width: 150px;
      height: 30px;
    }
  }
`;
