import React from 'react';
import { Button } from '@material-ui/core';

import MobileCarsListHeader from './header/MobileCarsListHeader';
import { availableCities, sortedAvailableCities, showCity } from '../WidgetWithout2GIS';

import { MobileNavWrapper, SideBarMenu } from './styles';

const MobileCarsList = ({ onClose, onSubmit }) => {
  return (
    <MobileNavWrapper>
      <SideBarMenu
        variant="persistent"
        anchor="right"
        open={true}
        PaperProps={{ className: 'menu' }}
      >
        <MobileCarsListHeader onClose={onClose} />
        <Button className="button" onClick={() => onSubmit(availableCities[0])}>
          Москва
        </Button>
        <Button className="button divider" onClick={() => onSubmit(availableCities[1])}>
          Санкт-Петербург
        </Button>
        {sortedAvailableCities.map((city) => showCity(city, false, (city) => onSubmit(city)))}
      </SideBarMenu>
    </MobileNavWrapper>
  );
};

export default MobileCarsList;
