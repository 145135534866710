import styled from 'styled-components';
import { StyledLink } from '../../../styledComponents';
import MapImage from '../../../assets/images/map.png';
import { breakpoints } from '../../../constants/breakpoints';
import { AppLinksWrapper } from '../../ui-kit/appLinks/styles';

export const SmallText = styled.span`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 14px;
  }
`;

export const Link = styled.a`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: 10px;
    line-height: 160%;
    color: #5d5d5d;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const CurrentPage = styled.span`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 10px;
    line-height: 160%;
    color: #222222;
  }
`;

export const SearchWidgetWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding: 110px 16px 0;
    }

    ${AppLinksWrapper} {
      margin-top: 30px;
    }

    .error {
      border: 1px solid red;
    }

    .form {
      width: 100%;
      box-shadow: 0px 4px 16px rgba(36, 93, 150, 0.15);
      border-radius: 4px;
      margin-bottom: 0;
    }

    .decoration {
      width: 361.09px;
      height: 130.39px;
    }

    ${StyledLink} {
      display: flex;
      align-items: center;
      margin-top: 24px;
      font-size: 12px;
      color: #99c6f6;
    }

    .icon-shield {
      margin-right: 8px;
    }

    & .insurance-logo {
      position: relative;
      left: 0;
      transform: translate(0, 0);
      margin-top: 20px;
    }

    .breadcrumbs {
      margin: 6px auto 0 0;
    }

    .breadcrumbs ol {
      display: flex;
      align-items: baseline;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 110px 24px 0px;
      margin-bottom: 0;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: 160px 36px 0;
      width: auto;
    }

    .form {
      width: auto;
    }

    .decoration {
      width: 615.62px;
      height: 222.31px;
    }

    ${StyledLink} {
      font-size: 16px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: 160px 52px 0;
    }
  }
`;

export const FormWrapper = styled.div`
  .autocomplete-error input {
    border-color: red;
  }

  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 15px;
      background: #ffffff;
      border-radius: 4px;
    }

    .icon {
      width: 14px;
      height: 14px;
    }

    .icon path {
      fill: #ffffff;
    }

    .location-wrap {
      width: 100%;
      margin-bottom: 15px;
    }

    .date-wrap {
      width: 100%;
      margin-bottom: 15px;
    }

    .label {
      display: block;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 2px;
    }

    & .suggest {
      width: 100%;
    }

    .suggest .select {
      border-radius: 4px;
    }

    /* TODO change to material ui class */
    .suggest > div > div {
      padding: 0;
    }

    /* TODO change to material ui class */
    .suggest > div > div input {
      padding: 12px 16px 12px 45px !important;
      background: url(${MapImage}) no-repeat;
      background-position-x: 16px;
      background-position-y: 13px;
      color: #303133;
    }

    .suggest > div > div input::placeholder {
      color: #303133;
      opacity: 1;
    }

    .btn {
      width: 100%;
      margin-top: auto;
      height: 46px;
      box-shadow: none;
      padding: 16px 12px;
    }
  }

  @media ${breakpoints.tablet} {
    .btn {
      padding: 16px 32px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      flex-direction: row;
      align-items: flex-start;
      width: auto;
    }

    .date-wrap {
      width: auto;
      margin-bottom: 0;
    }

    .location-wrap {
      width: 250px;
      margin-bottom: 0;
      margin-right: 15px;
    }

    .btn {
      width: auto;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      justify-content: center;
    }
  }
`;

export const CitiesPopup = styled.div`
    @media ${breakpoints.tablet} {

        & {
            padding: 30px;
        }

        .title {
            font-size: 18px;
            line-height: 20px;
            color: #222222;
            margin-bottom: 20px;
        }

        .list {
            display flex;
            flex-direction: row;
        }

        .divider {
            border-bottom: 1px solid #DBE3EF;
        }

        .verticalDivider {
            border-left: 1px solid #DBE3EF;
            border-right: 1px solid #DBE3EF;
        }

        .button {
            display: flex;
            justify-content: left;
            color: #3F3F3F;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            padding: 17px 20px 17px 20px;
            width: 266px;
            border-radius: 0;
            box-shadow: none;

            .letter {
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                color: #95A3B8;
                margin-top: -10px;
                margin-right: 10px;
            }

            .name {
                flex-wrap: wrap
            }
        }
    }
`;

export const PickerWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      border: 1px solid #8f9bb3;
      border-radius: 4px;
      padding: 6px 16px;
      width: 100%;
    }

    input,
    input:focus,
    input:hover {
      border: none !important;
      padding: 0;
      color: #303133;
    }

    .date-hours > select,
    .date-hours > select:focus,
    .date-hours > select:hover {
      border: none !important;
      padding: 0;
      color: #303133;
      min-width: 70px;
      background-color: #ffffff;
    }

    .date-hours > div {
      border: none;
      padding: 0 25px 0 0;
      color: #303133;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      margin-bottom: 0;
      margin-right: 15px;
      width: 290px;
    }
  }
`;

export const Title = styled.h1`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 24px;
    color: #222222;
    text-align: center;
  }

  @media ${breakpoints.laptop} {
    font-size: 48px;
    max-width: 1100px;
    margin-bottom: 35px;
  }
`;

export const Description = styled.span`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #222222;
    padding: 0 0 27px;
    text-align: center;
  }

  @media ${breakpoints.tablet} {
    margin-bottom: 25px;
    width: 60%;
  }

  @media ${breakpoints.laptop} {
    font-size: 24px;
    width: 100%;
  }
`;

export const KillerFeatures = styled.div`
  @media ${breakpoints.mobile} {
    & {
      margin-top: 80px;
    }

    .desktop-slider {
      display: none;
    }

    .tablet-slider {
      display: flex;
      max-width: 95vw;
      overflow-x: scroll;
      margin-bottom: 30px;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .killer-feature {
        min-width: 135px;
        height: 96px;
        margin-right: 10px;

        img {
          width: 135px;
          height: 96px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .killer-features {
      & {
        max-width: 100vw;
        width: 100%;
        padding-left: 36px;
        padding-right: 36px;
        margin-bottom: 30px;
      }

      .killer-feature {
        width: 315px;
        height: 109px;
        margin-right: 20px;

        img {
          width: 315px;
          height: 109px;
        }
      }

      .sample-arrow {
        width: 34px;
        height: 34px;

        &:before {
          content: none;
        }
      }

      .slick-prev {
        left: -25px;
      }

      button.slick-arrow {
        display: none !important;
      }
    }

    .slick-list {
      & {
        height: 114px;
      }

      .slick-track {
        display: flex;
      }
    }

    .slick-slide {
      & {
        width: 335px !important;
      }

      &__image {
        width: 315px;
        height: 109px;
      }
    }
  }

  @media ${breakpoints.tablet} {
  }

  @media ${breakpoints.laptop} {
    .desktop-slider {
      display: block;
    }

    .tablet-slider {
      display: none;
    }
  }

  @media ${breakpoints.desktop} {
    .killer-features {
      & {
        max-width: 90em;
      }

      .slick-prev {
        left: -40px;
      }
    }
  }
`;
