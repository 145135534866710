import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import Slider from 'react-slick';
import { Popover } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppLinksBlock from '../../ui-kit/appLinks';
import SearchIcon from '../../../assets/icons/search.svg';
import ArrowIcon from '../../../assets/icons/arrow-right.svg';
import { defaultEndRent, defaultStartRent, getRentInitialValues } from '../../../utils/date';
import { track } from '../../../seo';

import NoDepositeImage from '../../../assets/pictures/killer-features/no-deposite.png';
import PayAfterRentImage from '../../../assets/pictures/killer-features/pay-after-rent.png';
import KaskoImage from '../../../assets/pictures/killer-features/kasko.png';
import PrivetImage from '../../../assets/pictures/killer-features/privet.png';
import UnlimitedMileageImage from '../../../assets/pictures/killer-features/unlimited-mileage.png';
import OnlineOnlyImage from '../../../assets/pictures/killer-features/online-only.png';

import NoDepositeMobileImage from '../../../assets/pictures/killer-features/mobile/no-deposite.png';
import PayAfterRentMobileImage from '../../../assets/pictures/killer-features/mobile/pay-after-rent.png';
import KaskoMobileImage from '../../../assets/pictures/killer-features/mobile/kasko.png';
import PrivetMobileImage from '../../../assets/pictures/killer-features/mobile/privet.png';
import UnlimitedMileageMobileImage from '../../../assets/pictures/killer-features/mobile/unlimited-mileage.png';
import OnlineOnlyMobileImage from '../../../assets/pictures/killer-features/mobile/online-only.png';

import PrevArrowIcon from '../../../assets/icons/prevArrow.svg';
import NextArrowIcon from '../../../assets/icons/nextArrow.svg';

import MobileCarsList from './mobileCarsList/MobileCarsList';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { BackgroundWrapper } from '../../../styledComponents';
import {
  CitiesPopup,
  CurrentPage,
  Description,
  FormWrapper,
  KillerFeatures,
  Link,
  SearchWidgetWrapper,
  Title,
} from './styles';

export const availableCities = [
  {
    address: 'Москва',
    key: 'moscow',
    location: {
      bounds: '37.923687315056576%2C56.00400954445204%2C37.260010160539174%2C55.2840959491822',
    },
  },
  {
    address: 'Санкт-Петербург',
    key: 'stPetersburg',
    location: {
      bounds: '30.67460357537279%2C60.145927970035416%2C30.091552606178553%2C59.583274795054635',
    },
  },
  {
    address: 'Краснодар',
    key: 'krasnodar',
    location: {
      bounds: '39.21432909410287%2C45.262907255865954%2C38.82733569599969%2C44.736904200467755',
    },
  },
  {
    address: 'Сочи',
    location: {
      bounds: '40.37687715806164%2C44.06036955587499%2C39.486315109227895%2C42.91462870099871',
    },
  },
  {
    address: 'Новосибирск',
    location: {
      bounds: '83.204563%2C55.118185%2C82.695175%2C54.806745',
    },
  },
  {
    address: 'Екатеринбург',
    location: {
      bounds: '60.729171736099964%2C56.96732138051319%2C60.4626937555677%2C56.68704813173861',
    },
  },
  {
    address: 'Казань',
    location: {
      bounds: '49.260235%2C55.857121%2C49.006192%2C55.725065',
    },
  },
  {
    address: 'Нижний Новгород',
    location: {
      bounds: '44.31244638233186%2C56.649666988990376%2C43.67713777063419%2C55.97230511268308',
    },
  },
  {
    address: 'Красноярск',
    location: {
      bounds: '93.161277%2C56.108772%2C92.670805%2C55.930112',
    },
  },
  {
    address: 'Самара',
    location: {
      bounds: '50.38553961373%2C53.366625278193%2C49.999349127991366%2C52.921368080093416',
    },
  },
  {
    address: 'Уфа',
    location: {
      bounds: '56.141151%2C54.825941%2C55.880974%2C54.703862',
    },
  },
  {
    address: 'Ростов-на-Дону',
    location: {
      bounds: '39.91518037182139%2C47.46075795999468%2C39.553362216434216%2C46.988433595558',
    },
  },
  {
    address: 'Воронеж',
    location: {
      bounds: '39.299082%2C51.727756%2C39.107162%2C51.616671',
    },
  },
  {
    address: 'Пермь',
    location: {
      bounds: '56.42149874387669%2C58.17902712569756%2C56.041453876836435%2C57.79175140221016',
    },
  },
  {
    address: 'Тюмень',
    location: {
      bounds: '65.722742%2C57.228837%2C65.404825%2C57.071285',
    },
  },
  {
    address: 'Владивосток',
    location: {
      bounds: '132.032604%2C43.169345%2C131.812333%2C43.066855',
    },
  },
  {
    address: 'Анапа',
    location: {
      bounds: '37.403413%2C44.991547%2C37.21852%2C44.823405',
    },
  },
  {
    address: 'Калининград',
    location: {
      bounds: '21.043638582301714%2C55.36229173523583%2C19.86078141773221%2C54.04873070830888',
    },
  },
  {
    address: 'Челябинск',
    location: {
      bounds: '61.79006920244895%2C55.496076062452225%2C61.03018186531283%2C54.65993651194508',
    },
  },
  {
    address: 'Геленджик',
    location: {
      bounds: '38.15300296455196%2C44.65192778696307%2C37.99575962036885%2C44.43650705843386',
    },
  },
  {
    address: 'Новороссийск',
    location: {
      bounds: '37.832529127134116%2C44.77999608918054%2C37.712376289774426%2C44.615823789776776',
    },
  },
  {
    address: 'Кисловодск',
    location: {
      bounds: '43.821166729011125%2C44.89490072593527%2C42.31498956221046%2C42.68693461153691',
    },
  },
  {
    address: 'Пятигорск',
    location: {
      bounds: '43.821166729011125%2C44.89490072593527%2C42.31498956221046%2C42.68693461153691',
    },
  },
  {
    address: 'Минеральные воды',
    location: {
      bounds: '43.821166729011125%2C44.89490072593527%2C42.31498956221046%2C42.68693461153691',
    },
  },
  {
    address: 'Ессентуки',
    location: {
      bounds: '43.821166729011125%2C44.89490072593527%2C42.31498956221046%2C42.68693461153691',
    },
  },
  {
    address: 'Железноводск',
    location: {
      bounds: '43.821166729011125%2C44.89490072593527%2C42.31498956221046%2C42.68693461153691',
    },
  },
  {
    address: 'Лермонтов',
    location: {
      bounds: '43.821166729011125%2C44.89490072593527%2C42.31498956221046%2C42.68693461153691',
    },
  },
  {
    address: 'Изобильный',
    location: {
      bounds: '41.75612189754492%2C45.421041147844676%2C41.67254882464815%2C45.30817250429303',
    },
  },
];

export const sortedAvailableCities = availableCities.slice(2);
sortedAvailableCities.sort((a, b) => (a.address < b.address ? -1 : a.address > b.address ? 1 : 0));

const citiesPerColumn = Math.ceil(availableCities.length / 3);

const useStyles = makeStyles({
  select: {
    borderRadius: '4px',

    '&&:focus': {
      borderRadius: '4px',
      background: 'none',
    },
  },
  selectIcon: {
    display: 'none',
  },
});

export const SamplePrevArrow = ({ onClick }) => (
  <div className="sample-arrow slick-prev" onClick={onClick}>
    <PrevArrowIcon />
  </div>
);

export const SampleNextArrow = ({ onClick }) => (
  <div className="sample-arrow slick-next" onClick={onClick}>
    <NextArrowIcon />
  </div>
);

export const CustomSlide = ({ img }) => (
  <div className="killer-feature">
    <img src={img} alt="" />
  </div>
);

export const showCity = (city, isWithBorder, onSubmit) => {
  const firstLetter = city.address[0];

  const filteredListByFirstLetter = sortedAvailableCities.filter(
    (city) => city.address[0] === firstLetter,
  );

  return (
    <Button
      onClick={() => onSubmit(city)}
      className={isWithBorder ? 'button verticalDivider' : 'button'}
    >
      {city.address === filteredListByFirstLetter[0].address && (
        <span className="letter">{firstLetter}</span>
      )}
      <span className="name">{city.address}</span>
    </Button>
  );
};

// TODO add geoposition success and error functions
export default ({
  filters = '',
  title,
  text,
  lon,
  lat,
  initialAddress,
  breadcrumbs,
  withAppLinks,
  seo,
}) => {
  const [value, setValue] = useState('');

  const styles = useStyles();
  const pageBreadcrumbs = breadcrumbs && breadcrumbs.split('-');
  const defaultStartDate = defaultStartRent();
  const defaultEndDate = defaultEndRent(defaultStartDate);

  const handleChangeAddress = (event, setFieldValue) => {
    const { value } = event.target;
    const newValue = availableCities.find(({ address }) => address === value);

    setFieldValue('city', newValue);
    setValue(newValue);
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [isPrevBtnVisible, setIsPrevBtnVisible] = useState(false);
  const [isNextBtnVisible, setIsNextBtnVisible] = useState(true);

  const handleClickArrows = (index, lastIndex) => {
    index > 0 ? setIsPrevBtnVisible(true) : setIsPrevBtnVisible(false);
    index === lastIndex ? setIsNextBtnVisible(false) : setIsNextBtnVisible(true);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    arrows: true,
    afterChange: (index) => handleClickArrows(index, 2),
    prevArrow: isPrevBtnVisible && <SamplePrevArrow />,
    nextArrow: isNextBtnVisible && <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1045,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          afterChange: (index) => handleClickArrows(index, 3),
        },
      },
    ],
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [isMobileCarsListVisible, setIsMobileCarsListVisible] = useState(false);

  const handleOpenCarsList = (event) => {
    isMobile ? setIsMobileCarsListVisible(true) : setAnchorEl(event.currentTarget);
  };

  const handleCloseCarsList = () => {
    isMobile ? setIsMobileCarsListVisible(false) : setAnchorEl(null);
  };

  const isCarsListOpened = Boolean(anchorEl);
  const id = isCarsListOpened ? 'cars-list' : undefined;

  const handleSubmit = ({ location, address }) => {
    const dateStartQueries = encodeURIComponent(defaultStartDate);
    const dateEndQueries = encodeURIComponent(defaultEndDate);

    if (seo) {
      track(seo);
    }

    let query = `dateStart=${dateStartQueries}&dateEnd=${dateEndQueries}${filters}`;

    if (address) {
      query += `&address=${address}`;
    }

    if (location.bounds) {
      query += `&bounds=${location.bounds}`;
    }

    if (location.lat && location.lon) {
      query += `&location=${location.lat}%2C${location.lon}`;
    }

    const url = `/app/search?${query}`;

    window.location = url;
  };

  return (
    <SearchWidgetWrapper>
      <Title>{title}</Title>
      <Description>{text}</Description>
      {/* <CityIcon className="decoration" /> */}
      <Formik
        initialValues={{
          city: availableCities.find(({ address }) => address === initialAddress) || {},
        }}
      >
        {({
          values: { dateStart = defaultStartDate, dateEnd = defaultEndDate },
          submitForm,
          setFieldValue,
          errors,
          touched,
        }) => {
          const {
            dayStart,
            dayEnd,
            dayISOStart,
            timeStart,
            dayISOEnd,
            timeEnd,
          } = getRentInitialValues({
            dateStart,
            dateEnd,
          });
          return (
            <Form className="form">
              <FormWrapper>
                {/* <div className="location-wrap">
                  <Select
                    className="suggest"
                    classes={{ select: styles.select, icon: styles.selectIcon }}
                    value={value}
                    displayEmpty
                    renderValue={({ address }) => address || 'Выберите город'}
                    onChange={(event) => handleChangeAddress(event, setFieldValue)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {availableCities.map((city) => {
                      return <MenuItem value={city.address}>{city.address}</MenuItem>;
                    })}
                  </Select>
                  {errors.city && touched.city ? <StyledError>{errors.city}</StyledError> : null}
                </div>
                <div className="date-wrap">
                  <PickerWrapper className={errors.dateStart ? 'error' : ''}>
                    <DayPickerInput
                      // value={dayStart}
                      format="dd MMMM yyyy"
                      placeholder="Начало аренды"
                      dayPickerProps={{
                        selectedDays: [dayStart, { from: dayStart, to: dayEnd }],
                        disabledDays: { before: new Date() },
                        month: dayStart,
                        fromMonth: new Date(),
                        modifiers: { start: dayStart, end: dayEnd },
                        numberOfMonths: 1,
                      }}
                      onDayChange={(day) => {
                        const newDay = formatDateISO(day);
                        const nextDay = formatDateISO(addDays(day, defaultRentPeriod));
                        if (isAfter(day, dayEnd)) {
                          setFieldValue('dateEnd', joinDayAndHours(nextDay, timeEnd));
                        }
                        setFieldValue('dateStart', joinDayAndHours(newDay, timeStart));
                      }}
                      className="picker-wrap"
                    />
                    <Select
                      id="startHour"
                      native
                      className="hourStart date-hours"
                      value={timeStart}
                      input={<Input className="autocomplete" />}
                      onChange={({ target: { value } }) =>
                        setFieldValue('dateStart', joinDayAndHours(dayISOStart, value))
                      }
                    >
                      {timeSlots.map((time) => (
                        <option key={`start-${time}`} value={time}>
                          {time}
                        </option>
                      ))}
                    </Select>
                  </PickerWrapper>
                </div>
                <div className="date-wrap">
                  <PickerWrapper className={errors.dateEnd ? 'error' : ''}>
                    <DayPickerInput
                      // value={dayEnd}
                      format="dd MMMM yyyy"
                      placeholder="Конец аренды"
                      dayPickerProps={{
                        selectedDays: [dayStart, { from: dayStart, to: dayEnd }],
                        // TODO add correct after (with hours)
                        disabledDays: { before: dayStart },
                        modifiers: { start: dayStart, end: dayEnd },
                        month: dayStart,
                        fromMonth: dayStart,
                        numberOfMonths: 1,
                      }}
                      onDayChange={(day) => {
                        const newDay = formatDateISO(day);
                        setFieldValue('dateEnd', joinDayAndHours(newDay, timeEnd));
                      }}
                      className="picker-wrap"
                    />
                    <Select
                      id="endHour"
                      native
                      className="hourEnd date-hours"
                      value={timeEnd}
                      input={<Input className="autocomplete" />}
                      onChange={({ target: { value } }) =>
                        setFieldValue('dateEnd', joinDayAndHours(dayISOEnd, value))
                      }
                    >
                      {timeSlots.map((time) => (
                        <option key={`end-${time}`} value={time}>
                          {time}
                        </option>
                      ))}
                    </Select>
                  </PickerWrapper>
                </div> */}
                <Button
                  startIcon={<SearchIcon className="icon" />}
                  variant="contained"
                  color="secondary"
                  onClick={handleOpenCarsList}
                  className="btn"
                >
                  Показать свободные машины
                </Button>
                <Popover
                  id={id}
                  open={isCarsListOpened}
                  anchorEl={anchorEl}
                  onClose={handleCloseCarsList}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <CitiesPopup>
                    <h3 className="title">Выберите город</h3>
                    <div className="list">
                      <div>
                        <Button className="button" onClick={() => handleSubmit(availableCities[0])}>
                          Москва
                        </Button>
                        <Button
                          className="button divider"
                          onClick={() => handleSubmit(availableCities[1])}
                        >
                          Санкт-Петербург
                        </Button>
                        {sortedAvailableCities.map(
                          (city, i) =>
                            i < citiesPerColumn - 2 &&
                            showCity(city, false, (city) => handleSubmit(city)),
                        )}
                      </div>
                      <div>
                        {sortedAvailableCities.map(
                          (city, i) =>
                            i >= citiesPerColumn - 2 &&
                            i < citiesPerColumn + (citiesPerColumn - 2) &&
                            showCity(city, true, (city) => handleSubmit(city)),
                        )}
                      </div>
                      <div>
                        {sortedAvailableCities.map(
                          (city, i) =>
                            i >= citiesPerColumn + (citiesPerColumn - 2) &&
                            showCity(city, false, (city) => handleSubmit(city)),
                        )}
                      </div>
                    </div>
                  </CitiesPopup>
                </Popover>
                {isMobileCarsListVisible && (
                  <MobileCarsList onClose={handleCloseCarsList} onSubmit={handleSubmit} />
                )}
                {/* {errors.dateStart ? (
                    <StyledError className="error ">{errors.dateStart}</StyledError>
                  ) : null}
                  {errors.dateEnd ? (
                    <StyledError className="error">{errors.dateEnd}</StyledError>
                  ) : null} */}
              </FormWrapper>
            </Form>
          );
        }}
      </Formik>
      {pageBreadcrumbs && (
        <Breadcrumbs
          className="breadcrumbs"
          separator={<ArrowIcon className="nav-icon" />}
          aria-label="breadcrumb"
        >
          <Link href="/">Главная</Link>
          {breadcrumbs !== 'Краснодар' && <Link href="/car-rental-krasnodar">Краснодар</Link>}
          {pageBreadcrumbs.map((breadcrumb, index) =>
            index === pageBreadcrumbs.length - 1 ? (
              <CurrentPage>{breadcrumb}</CurrentPage>
            ) : (
              <Link href="./">{breadcrumb}</Link>
            ),
          )}
        </Breadcrumbs>
      )}
      {/* <StyledLink block href="/car-rental-income" title="Начать зарабатывать на своем автомобиле">
        <VerifiedUserIcon className="icon-shield" />
        Начать зарабатывать на своем автомобиле
      </StyledLink> */}
      {withAppLinks && <AppLinksBlock />}

      <BackgroundWrapper>
        <KillerFeatures>
          <div className="desktop-slider">
            <Slider className="killer-features" {...settings}>
              <CustomSlide img={PrivetImage} />
              <CustomSlide img={PayAfterRentImage} />
              <CustomSlide img={NoDepositeImage} />
              <CustomSlide img={KaskoImage} />
              <CustomSlide img={UnlimitedMileageImage} />
              <CustomSlide img={OnlineOnlyImage} />
            </Slider>
          </div>
          <div className="tablet-slider">
            <CustomSlide img={isMobile || isTablet ? PrivetMobileImage : PrivetImage} />
            <CustomSlide img={isMobile || isTablet ? PayAfterRentMobileImage : PayAfterRentImage} />
            <CustomSlide img={isMobile || isTablet ? NoDepositeMobileImage : NoDepositeImage} />
            <CustomSlide img={isMobile || isTablet ? KaskoMobileImage : KaskoImage} />
            <CustomSlide
              img={isMobile || isTablet ? UnlimitedMileageMobileImage : UnlimitedMileageImage}
            />
            <CustomSlide img={isMobile || isTablet ? OnlineOnlyMobileImage : OnlineOnlyImage} />
          </div>
        </KillerFeatures>
      </BackgroundWrapper>

      {/* <InsuranceIngostrah className="insurance-logo" />
      <SmallText>страховой партнер маркетплейса</SmallText> */}
    </SearchWidgetWrapper>
  );
};
