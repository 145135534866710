import styled from 'styled-components';
import MobileImage from '../../../assets/images/194.jpg';
import LaptopImage from '../../../assets/images/195.jpg';
import PhoneImage from '../../../assets/images/196.png';
import { breakpoints } from '../../../constants/breakpoints';

export const LogoWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 55px;
      height: 55px;
      background: #222222;
      box-shadow: 0px 2.55814px 10.2326px rgba(105, 105, 105, 0.46);
      border-radius: 2.55814px;
      padding: 15px 10px;
      box-sizing: border-box;
    }

    .logo-icon {
      fill: #ffffff;
      width: 34.94px;
      height: 25.11px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      width: 76px;
      height: 76px;
      padding: 20px 14px;
    }

    .logo-icon {
      width: 48.28px;
      height: 34.7px;
    }
  }
`;

export const Title = styled.h2`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 32px;
  }

  @media ${breakpoints.desktop} {
    width: 474px;
  }
`;

export const Text = styled.p`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 16px;
    line-height: 150%;
  }

  @media ${breakpoints.desktop} {
    width: 392px;
  }
`;

export const Container = styled.div`
  @media ${breakpoints.desktop} {
    background: url(${PhoneImage}) no-repeat;
    background-position: right;
    background-size: contain;
  }
`;

export const Content = styled.div`
  @media ${breakpoints.mobile} {
    & {
      padding: 40px 51px 40px 16px;
      background: url(${MobileImage}) no-repeat;
      background-size: cover;
      border-radius: 4px;
    }

    ${LogoWrapper} {
      margin-bottom: 16px;
    }

    ${Title} {
      margin-bottom: 18px;
    }

    ${Text} {
      margin-bottom: 40px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      width: calc(100% - 247px);
      box-sizing: content-box;
      padding: 100px 133px 0 114px;
      background: right / cover no-repeat url(${LaptopImage});
    }

    ${Title} {
      margin-bottom: 24px;
    }

    .apps {
      padding-bottom: 100px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
    }
  }
`;

export const AppBlockWrapper = styled.div`
  @media ${breakpoints.mobile} {
    padding: 0 15px;
    width: 100%;
  }

  @media ${breakpoints.tablet} {
    padding: 0 24px;
  }

  @media ${breakpoints.laptop} {
    padding: 0 36px;
  }

  @media ${breakpoints.desktop} {
    padding: 0 52px;
    max-width: 1440px;
  }
`;
