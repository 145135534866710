import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Rating from '@material-ui/lab/Rating';

import { getDate } from '../../utils/date';
import { StyledLink } from '../../styledComponents';
import { ReviewWrapper, ReviewContent, ReviewComment, ReviewName, ReviewDate } from './styles';

const Review = ({ rating, comment, createdAt, className, reviewer }) => {
  const { uuid: reviewerId, avatar, firstName, lastName } = reviewer;
  return (
    <div className={className}>
      <ReviewWrapper>
        <Avatar alt="avatar" src={avatar} className="avatar" />
        <ReviewContent>
          <Rating name="read-only" className="rating-stars" value={rating} readOnly />
          <ReviewName>{`${firstName} ${lastName}`}</ReviewName>
          <ReviewComment>{comment}</ReviewComment>
          <ReviewDate>{getDate(createdAt)}</ReviewDate>
          {/* <StyledLink href={`/app/account/public/${reviewerId}`}>Перейти в профиль</StyledLink> */}
        </ReviewContent>
      </ReviewWrapper>
    </div>
  );
};

export default Review;
