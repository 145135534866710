import React, { useState } from 'react';
import { Link } from 'gatsby';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import ReferalPopup from '../../../../header/popup';
import { DesktopNav } from '../../../../header/DesktopNav/DesktopNav';
import { MobileNav } from '../../../../header/MobileNav/MobileNav';
import LogoTextIcon from '../../../../../assets/icons/logo-text.svg';
import LogoIcon from '../../../../../assets/icons/logo.svg';
import ArrowIcon from '../../../../../assets/icons/arrow-left.svg';

import { Header, Content } from './styles';

const MobileCarsListHeader = ({ onClose }) => {
  const [open, setOpen] = useState(false);

  const isLaptop = useMediaQuery('(max-width:1023px)');
  const isMoreThenMobile = useMediaQuery('(min-width:1024px)');

  const handleClose = () => setOpen(false);

  const handleClickOpen = () => setOpen(true);

  return (
    <Header className="header">
      <Content>
        <Button className="back-btn" onClick={onClose}>
          <ArrowIcon />
        </Button>
        <h3 className="title">Выберите город</h3>
        <Toolbar className="toolbar">
          {isLaptop && <MobileNav openReferalPopup={handleClickOpen} />}
          {isMoreThenMobile && <DesktopNav openReferalPopup={handleClickOpen} />}
        </Toolbar>
        <Dialog
          scroll="body"
          maxWidth={false}
          fullScreen={isLaptop}
          open={open}
          onClose={handleClose}
        >
          <ReferalPopup onClose={handleClose} />
        </Dialog>
      </Content>
    </Header>
  );
};

export default MobileCarsListHeader;
