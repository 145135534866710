import React from 'react';
import Grid from '@material-ui/core/Grid';
import ZarcinImage from '../../assets/images/zarcin-and-partners.jpeg';
import CrocImage from '../../assets/images/croc.jpeg';
import IngosImage from '../../assets/images/ingosstrah.jpeg';
import TinkoffImage from '../../assets/images/tinkoff.jpeg';
import MetaImage from '../../assets/images/meta.jpeg';
import IdxImage from '../../assets/images/idx.jpeg';

import { Title, Company } from '../../styledComponents';
import { PartnersWrapper, Image } from './styles';

const Partners = ({ withoutTopMargin, LTR }) => (
  <PartnersWrapper withoutTopMargin={withoutTopMargin} LTR={LTR}>
    <Title className="title">
      Партнеры <Company>Getarent</Company>
    </Title>
    <Grid className="box" container spacing={3}>
      <Grid className="item" item sm={12} md={4} lg={2} xl={2}>
        <Image src={IngosImage} />
      </Grid>
      <Grid className="item" item sm={12} md={4} lg={2} xl={2}>
        <Image src={TinkoffImage} />
      </Grid>
      <Grid className="item" item sm={12} md={4} lg={2} xl={2}>
        <Image src={CrocImage} />
      </Grid>
      <Grid className="item" item sm={12} md={4} lg={2} xl={2}>
        <Image src={ZarcinImage} />
      </Grid>
      <Grid className="item" item sm={12} md={4} lg={2} xl={2}>
        <Image src={MetaImage} />
      </Grid>
      <Grid className="item" item sm={12} md={4} lg={2} xl={2}>
        <Image src={IdxImage} />
      </Grid>
    </Grid>
  </PartnersWrapper>
);

export default Partners;
