import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import NavIcon from '../../../assets/icons/swiper-nav.svg';
import 'swiper/css/swiper.min.css';

import { SwiperWrapper } from './styles';

const SwiperComponent = ({
  name,
  margin,
  children,
  height,
  options = {},
  isOneSlide,
  customNav: CustomNav,
}) => {
  const swiper = useRef(null);

  useEffect(() => {
    swiper.current =
      !isOneSlide &&
      new Swiper(`.${name}`, {
        slidesPerView: 'auto',
        allowTouchMove: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        effect: 'slide',
        direction: 'horizontal',
        maxBackfaceHiddenSlides: 1,
        // nested: true,
        passiveListeners: false,
        // preloadImages: false,
        preventInteractionOnTransition: true,
        resistance: false,
        resizeObserver: false,
        simulateTouch: false,
        // slidesPerGroupAuto: true,
        touchEventsTarget: 'container',
        touchMoveStopPropagation: true,
        touchStartForcePreventDefault: true,
        updateOnWindowResize: false,
        watchOverflow: false,
        ...options,
      });
  }, [name, margin, options, isOneSlide]);

  return (
    <SwiperWrapper className={`swiper-container ${name}`} height={height} ref={swiper}>
      <div className="swiper-wrapper">{children}</div>
      <div className={`swiper-button-next ${CustomNav && 'custom'}`}>
        {CustomNav ? <CustomNav className="nav -next" /> : <NavIcon className="nav -next" />}
      </div>
      <div className={`swiper-button-prev ${CustomNav && 'custom'}`}>
        {CustomNav ? <CustomNav className="nav -prev" /> : <NavIcon className="nav -prev" />}
      </div>
      <div className="decorator" />
    </SwiperWrapper>
  );
};

export default SwiperComponent;
