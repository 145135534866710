import styled from 'styled-components';
import { Title, StyledLink } from '../../styledComponents';
import { breakpoints } from '../../constants/breakpoints';

export const ReviewsWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
      max-width: 1440px;
      padding: ${(props) => (props.withoutMarginBottom ? '60px 15px 0' : '60px 15px')};
      padding-top: ${(props) => (props.withoutMarginTop ? '0' : '60px')};
      box-sizing: border-box;
    }

    ${Title} {
      margin-bottom: 40px;
      text-align: ${({ LTR }) => (LTR ? 'center' : 'left')};
    }

    ${StyledLink} {
      width: max-content;
      margin-top: 10px;
    }

    .swiper-slide {
      width: auto;
    }

    .swiper-container {
      overflow: hidden;
      width: 100%;
    }

    .swiper-wrapper {
      width: auto;
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: 40%;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: ${(props) => (props.withoutMarginBottom ? '60px 24px 0' : '60px 24px')};
      padding-top: ${(props) => (props.withoutMarginTop ? '0' : '60px')};
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: ${(props) => (props.withoutMarginBottom ? '100px 36px 0' : '100px 36px')};
      padding-top: ${(props) => (props.withoutMarginTop ? '0' : '100px')};
    }

    ${Title} {
      margin-bottom: 60px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: ${(props) => (props.withoutMarginBottom ? '100px 54px 0' : '100px 54px')};
      padding-top: ${(props) => (props.withoutMarginTop ? '0' : '100px')};
    }
  }
`;

export const ReviewWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      width: 330px;
      height: 220px;
      box-sizing: border-box;
      border: 1px solid #e7e7e8;
      background: #ffffff;
      border-radius: 8px;
      padding: 20px;
    }

    .avatar {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    .avatar img {
      margin-bottom: 0;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      width: 420px;
      height: 240px;
      padding: 40px;
    }

    .avatar {
      width: 50px;
      height: 50px;
    }
  }
`;

export const ReviewName = styled.h3`
  @media ${breakpoints.mobile} {
    font-size: 16px;
    line-height: 140%;
    font-weight: 700;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 18px;
    line-height: 150%;
  }
`;

export const ReviewComment = styled.p`
  @media ${breakpoints.mobile} {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    line-height: 150%;
    color: #222222;
    font-weight: 400;
    margin-bottom: auto;
  }
`;

export const ReviewDate = styled.span`
  @media ${breakpoints.mobile} {
    font-size: 14px;
    line-height: 160%;
    font-weight: 400;
    color: #b8b8b8;
  }

  @media ${breakpoints.laptop} {
    /* position: absolute;
    right: 40px;
    top: 37px; */
  }
`;

export const ReviewContent = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: calc(100% - 38px);
      display: flex;
      flex-direction: column;
    }

    .rating-stars {
      font-size: 14px;
      margin-bottom: 4px;
    }

    ${ReviewName} {
      margin-bottom: 8px;
    }

    ${ReviewComment} {
      margin-bottom: auto;
    }
  }
`;
