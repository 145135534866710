import React from 'react';

import { yandexTracks, googleTracks } from '../../../seo';
import AppLinks from '../../ui-kit/appLinks';
import LogoIcon from '../../../assets/icons/logo.svg';

import { Colored } from '../../../styledComponents';
import { AppBlockWrapper, Content, LogoWrapper, Text, Title, Container } from './styles';

const titleComponent = (
  <>
    Забронируйте автомобиль в <Colored color="#066BD6">мобильном приложении</Colored>
  </>
);

const AppLinksBlock = ({ title = titleComponent }) => {
  return (
    <AppBlockWrapper>
      <Content>
        <Container>
          <LogoWrapper>
            <LogoIcon className="logo-icon" />
          </LogoWrapper>
          <Title>{title}</Title>
          <Text>
            Управляйте арендами, общайтесь с владельцами перед бронированием и оставляйте отзывы
          </Text>
          <AppLinks
            className="apps"
            seo={{
              apple: [yandexTracks.homePageAppstore, googleTracks.homePageAppstore],
              google: [yandexTracks.homePageGoogleplay, googleTracks.homePageGoogleplay],
            }}
          />
        </Container>
      </Content>
    </AppBlockWrapper>
  );
};

export default AppLinksBlock;
