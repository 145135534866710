import styled from 'styled-components';
import { Title } from '../../styledComponents';
import { breakpoints } from '../../constants/breakpoints';

export const PartnersWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
      max-width: 1440px;
      padding: ${(props) => (props.withoutTopMargin ? '0 0 60px' : '60px 0 60px')};
      box-sizing: border-box;
      text-align: center;
    }

    .title {
      margin-bottom: 50px;
    }

    ${Title} {
      text-align: ${(props) => (props.LTR ? 'center' : 'left')};
    }

    .box {
      width: 100%;
      padding: 0 15px;
      margin: 0;
    }
  }

  @media ${breakpoints.tablet} {
    .box {
      padding: 0 24px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: ${(props) => (props.withoutTopMargin ? '0 0 100px' : '100px 0')};
    }

    .title {
      margin-bottom: 60px;
    }

    .box {
      padding: 0 36px;
      margin: -12px 0 0 -12px;
    }

    .MuiGrid-spacing-xs-3 > .MuiGrid-item {
      padding: 12px 25px;
    }

    ${Title} {
      text-align: ${(props) => (props.LTR ? 'center' : 'left')};
      margin-bottom: 60px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: ${(props) => (props.withoutTopMargin ? '0 0 100px' : '100px 0 100px')};
    }

    .box {
      padding: 0 54px;
    }

    ${Title} {
      margin-left: 54px;
    }
  }
`;

export const Image = styled.div`
  @media ${breakpoints.mobile} {
    width: 100%;
    height: 70px;
    background: ${(props) => `url(${props.src}) center no-repeat`};
    background-size: contain;
  }

  @media ${breakpoints.desktop} {
    height: 100px;
  }
`;
